import { Endpoint } from "./../network/endpoint"
import { HttpManager } from "../network/interface/HttpManager"
import { TransactionsError } from "../../ui/error/TransactionsError"
import { TransactionDataFilter } from "../dto/TransactionDataFilter"
import { Filter } from "../../domain/types/transactions/Filter"
import { Transaction } from "../../domain/entities/Transaction"
import { CreateTransactionDTO } from "../dto/CreateTransaction.dto"

export class TransactionsService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	public async uploadTransactions(file: FormData): Promise<{ code: string; message: string }> {
		try {
			const response = await this._http.requestNewApi({
				url: Endpoint.TRANSACTION_UPLOAD_FILE,
				method: "POST",
				data: file,
				headers: { "Content-Type": "multipart/form-data" }
			})
			return response.data
		} catch (error: any) {
			throw new TransactionsError({
				errorMessage: TransactionsError.TRANSACTIONS_UPLOAD_FILE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async getTransactions(offset?: number, limit?: number, filter?: Filter) {
		try {
			const queryFilter = this.buildFilterQuery(filter)
			const response = await this._http.requestNewApi({
				url: `${Endpoint.TRANSACTIONS}?page=${offset}&limit=${limit}&${queryFilter}`,
				method: "GET"
			})
			return response.data
		} catch (error: any) {
			throw new TransactionsError({
				errorMessage: TransactionsError.TRANSACTIONS_GET_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async createTransaction(transaction: CreateTransactionDTO): Promise<number> {
		try {
			const response = await this._http.requestNewApi({
				url: `${Endpoint.TRANSACTIONS}/admin`,
				method: "POST",
				data: transaction
			})
			return response.data.id
		} catch (error: any) {
			throw new TransactionsError({
				errorMessage: TransactionsError.TRANSACTIONS_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateTransaction(transaction: Transaction, sendEmails: boolean) {
		try {
			const response = await this._http.requestNewApi({
				url: `${Endpoint.TRANSACTIONS}/admin/${transaction.id}`,
				method: "PATCH",
				data: {
					serviceId: transaction.serviceId,
					serviceSessionId: transaction.sessionId,
					serviceOfficeId: transaction.serviceOfficeId,
					currency: transaction.currency,
					marketplace: transaction.marketplace ?? null,
					micrositeId: transaction.micrositeId ?? null,
					voucherRuleId: transaction.voucherRuleId,
					paymentGateway: transaction.paymentGateway,
					email: transaction.userEmail,
					paymentBaseAmount: transaction.paymentBaseAmount,
					paymentFinalAmount: transaction.paymentFinalAmount,
					status: transaction.status,
					requestDate: transaction.requestDate,
					units: transaction.units,
					startDateTime: transaction.eventDateTime,
					cancelledAt: transaction.cancelledAt,
					observations: transaction.observations,
					appointmentStatus: transaction.appointmentStatus,
					points: transaction.points,
					sendEmails
				}
			})
			return response.data
		} catch (error: any) {
			throw new TransactionsError({
				errorMessage: TransactionsError.TRANSACTIONS_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async downloadTransactions(offset: number, limit: number, filter?: Filter): Promise<Blob> {
		try {
			const queryFilter = this.buildFilterQuery(filter)
			const response = await this._http.requestNewApi({
				url: `${Endpoint.TRANSACTIONS_DOWNLOAD}?page=${offset}&limit=${limit}&${queryFilter}`,
				method: "GET",
				responseType: "blob"
			})
			return response.data
		} catch (error: any) {
			throw new TransactionsError({
				errorMessage: TransactionsError.TRANSACTIONS_GET_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	private buildFilterQuery(filter?: Filter) {
		let filterObject: TransactionDataFilter = {}
		if (filter?.createdAt) {
			filterObject = {
				...filterObject,
				dateFrom: new Date(
					Date.UTC(
						filter.createdAt[0].year(),
						filter.createdAt[0].month(),
						filter.createdAt[0].date(),
						0,
						0,
						0
					)
				).toISOString(),
				dateTo: new Date(
					Date.UTC(
						filter.createdAt[1].year(),
						filter.createdAt[1].month(),
						filter.createdAt[1].date(),
						23,
						59,
						59
					)
				).toISOString()
			}
		}
		if (filter?.salesSite == "marketplace") {
			filterObject = { ...filterObject, marketplace: filter.country }
		} else {
			if (filter?.microsite) {
				filterObject = { ...filterObject, micrositeId: filter.microsite }
			}
			if (filter?.salesSite == "microsite") {
				filterObject = { ...filterObject, micrositeBusinessTypeId: filter.micrositeType }
			}
		}

		const filterQuery = filterObject as { [key: string]: any }

		return filterQuery
			? Object.keys(filterQuery)
					.map(key => `${key}=${filterQuery[key]}`)
					.join("&")
			: ""
	}
}
