import { MicrositeObjective } from "../../domain/entities/MicrositeObjective"

interface ContructorParams {
	objectiveAmount?: number
	rewardAmount?: number
}

export class MicrositeObjectiveModel {
	public objectiveAmount?: number
	public rewardAmount?: number

	constructor(params: ContructorParams) {
		this.objectiveAmount = params.objectiveAmount
		this.rewardAmount = params.rewardAmount
	}

	static fromRawJson = (rawJson: any): MicrositeObjectiveModel => {
		const { objectiveAmount, rewardAmount } = rawJson

		return new MicrositeObjectiveModel({
			objectiveAmount,
			rewardAmount
		})
	}

	static fromDomain = (objective: MicrositeObjective): MicrositeObjectiveModel => {
		return new MicrositeObjectiveModel({
			...objective
		})
	}
}

declare module "./MicrositeObjectiveModel" {
	interface MicrositeObjectiveModel {
		toDomain(): MicrositeObjective
	}
}

MicrositeObjectiveModel.prototype.toDomain = function (): MicrositeObjective {
	const data = this
	return new MicrositeObjective({ ...data })
}
