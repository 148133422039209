import { BillingRepository } from "../../../repositories/billingRepository"
import { UseCase } from "../../useCase"
import { UpdateTransactionCreatedDateRequest } from "./request"
import { UpdateTransactionCreatedDateResponse } from "./response"

export class UpdateTransactionCreatedDateUseCase
	implements UseCase<UpdateTransactionCreatedDateRequest, UpdateTransactionCreatedDateResponse>
{
	private _repository
	constructor({ BillingRepository }: { BillingRepository: BillingRepository }) {
		this._repository = BillingRepository
	}
	exec({
		invoiceId,
		createdDate,
		transactionIds
	}: UpdateTransactionCreatedDateRequest): Promise<UpdateTransactionCreatedDateResponse> {
		return this._repository.updateTransactionCreatedDate(transactionIds, createdDate, invoiceId)
	}
}
