interface ContructorParams {
	objectiveAmount?: number
	rewardAmount?: number
}

export class MicrositeObjective {
	public objectiveAmount?: number
	public rewardAmount?: number

	constructor(params: ContructorParams) {
		this.objectiveAmount = params.objectiveAmount
		this.rewardAmount = params.rewardAmount
	}
}
