import { BusinessTypeEnum, Microsite } from "../../domain/entities/Microsite"
import { MicrositeDetailModel } from "./MicrositeDetailModel"
import { MicrositeObjectiveModel } from "./MicrositeObjectiveModel"

interface MicrositeCluster {
	id?: string
	status?: boolean
	clusterId: string
}

interface MicrositeService {
	id: string
	title: string
	weight: number
	highlighted: boolean
	seller: {
		id?: number
		name: string
	}
	serviceClusters?: {
		clusterId: number
		clusterName: string
	}[]
	updated?: boolean
	new?: boolean
}

interface MicrositeSetting {
	hasUserAuth: boolean
	businessType?: BusinessTypeEnum
	showWhatsapp: boolean
}

interface ContructorParams {
	id?: string
	sellerId: string
	sellerName: string
	partnerType: string
	slug: string
	status: number | boolean
	popupText?: string
	popupTextEn?: string
	externalPurchaseUrl?: string
	sporttipsLogo?: string
	partnerLogo?: string
	infoIcon?: string
	color: string
	contrastColor?: string
	font?: string
	currency?: string
	businessType?: string
	lang: string
	availableLanguages?: string[]
	showLogo?: number | boolean
	microSitesDetail: MicrositeDetailModel[]
	services: MicrositeService[]
	feePercent?: number
	settings?: MicrositeSetting
	whatsappNumber?: string
	pointsEnabled?: boolean
	objective?: MicrositeObjectiveModel | null
}
export class MicrositeModel {
	public id?: string
	public sellerId: string
	public sellerName: string
	public partnerType: string
	public slug: string
	public status: number | boolean
	public popupText?: string
	public popupTextEn?: string
	public externalPurchaseUrl?: string
	public sporttipsLogo?: string
	public partnerLogo?: string
	public infoIcon?: string
	public color: string
	public contrastColor?: string
	public font?: string
	public currency?: string
	public lang: string
	public availableLanguages?: string[]
	public showLogo: number | boolean
	public microSitesDetail: MicrositeDetailModel[]
	public services: MicrositeService[]
	public settings?: MicrositeSetting
	public feePercent?: number
	public whatsappNumber?: string
	public pointsEnabled: boolean
	public objective?: MicrositeObjectiveModel | null

	constructor(params: ContructorParams) {
		this.id = params.id
		this.sellerId = params.sellerId
		this.sellerName = params.sellerName
		this.partnerType = params.partnerType
		this.slug = params.slug
		this.status = params.status
		this.popupText = params.popupText
		this.popupTextEn = params.popupTextEn
		this.externalPurchaseUrl = params.externalPurchaseUrl
		this.sporttipsLogo = params.sporttipsLogo
		this.partnerLogo = params.partnerLogo
		this.infoIcon = params.infoIcon
		this.color = params.color
		this.contrastColor = params.contrastColor
		this.font = params.font
		this.currency = params.currency
		this.lang = params.lang?.toLowerCase()
		this.availableLanguages = params.availableLanguages
		this.showLogo = params.showLogo ?? false
		this.microSitesDetail = params.microSitesDetail
		this.services = params.services
		this.feePercent = params.feePercent
		this.settings = params.settings
		this.whatsappNumber = params.whatsappNumber
		this.pointsEnabled = params.pointsEnabled ?? false
		this.objective = params.objective
	}

	static fromRawJson = (rawJson: any): MicrositeModel => {
		return new MicrositeModel({
			...rawJson,
			objective: rawJson.objective ? MicrositeObjectiveModel.fromRawJson(rawJson.objective) : null,
			microSitesDetail: rawJson.microSitesDetail?.map((detail: any) => MicrositeDetailModel.fromRawJson(detail))
		})
	}

	static fromDomain = (microsite: Microsite): MicrositeModel => {
		return new MicrositeModel({
			...microsite,
			settings: {
				businessType: microsite.settings?.businessType,
				hasUserAuth: microsite.settings?.hasUserAuth || false,
				showWhatsapp: microsite.settings?.showWhatsapp || false
			},
			status: microsite.status ? 1 : 0,
			feePercent: microsite.feePercentage,
			partnerType: microsite.micrositeType,
			font: microsite.fontType,
			partnerLogo: microsite.partnerLogo,
			sporttipsLogo: microsite.sporttipsLogo,
			infoIcon: microsite.infoIcon || Microsite.DEFAULT_INFO_ICON,
			contrastColor: microsite.contrastColor,
			sellerName: microsite.sellerName,
			popupText: microsite.popupText,
			showLogo: microsite.showLogo ? 1 : 0,
			microSitesDetail: microsite.clusters.map((cluster: MicrositeCluster) =>
				MicrositeDetailModel.fromDomain(cluster)
			),
			externalPurchaseUrl: microsite.externalLink,
			services: microsite.services.map(service => ({
				...service,
				highlighted: !!Number(service.highlighted),
				weight: Number(service.weight)
			})),
			whatsappNumber: microsite.whatsappNumber,
			objective: microsite.objective ? MicrositeObjectiveModel.fromDomain(microsite.objective) : null
		})
	}
}

declare module "./MicrositeModel" {
	interface MicrositeModel {
		toDomain(): Microsite
	}
}

MicrositeModel.prototype.toDomain = function (): Microsite {
	const data = this
	return new Microsite({
		...data,
		settings: {
			businessType: data.settings?.businessType,
			hasUserAuth: data.settings?.hasUserAuth || false,
			showWhatsapp: data.settings?.showWhatsapp || false
		},
		feePercentage: data.feePercent,
		micrositeType: data.partnerType?.toString() || "",
		popupTextEn: data.popupTextEn || "",
		showLogo: data.showLogo == 1,
		status: data.status == 1,
		partnerLogo: data.partnerLogo,
		sporttipsLogo: data.sporttipsLogo,
		infoIcon: data.infoIcon,
		contrastColor: data.contrastColor,
		sellerName: data.sellerName,
		popupText: data.popupText,
		fontType: data.font || "",
		externalLink: data.externalPurchaseUrl || "",
		clusters: data.microSitesDetail?.map(detail => detail.toDomain()),
		services: data.services?.map(service => ({
			...service,
			highlighted: service.highlighted ? "1" : "0",
			weight: String(service.weight),
			cluster: service.serviceClusters
		})),
		whatsappNumber: data.whatsappNumber,
		objective: data.objective?.toDomain()
	})
}
