import { InvoiceStatus } from "../../domain/enum/invoiceStatus.enum"
import type { InvoicesFilter } from "../../domain/types/billing/InvoicesFilter"
import { BillingError } from "../../ui/error/BillingError"
import { InvoiceModel } from "../models/InvoiceModel"
import { Endpoint } from "../network/endpoint"
import { HttpManager } from "./../network/interface/HttpManager"

export class BillingService {
	private _http: HttpManager

	constructor({ HttpManager }: { HttpManager: HttpManager }) {
		this._http = HttpManager
	}

	private buildQueryFilter(filter: InvoicesFilter) {
		return Object.entries(filter)
			.filter(([param, value]) => param !== "offset" && param !== "limit" && !!value)
			.map(([param, value]) => `${param}=${Array.isArray(value) ? value.join(",") : value}`)
			.join("&")
			.concat(`&page=${Math.floor(filter.offset / filter.limit)}&limit=${filter.limit}`)
	}

	public async getInvoices(filter: InvoicesFilter): Promise<InvoiceModel[]> {
		try {
			const queryFilter = this.buildQueryFilter(filter)
			const response = await this._http.requestNewApi({
				url: `${Endpoint.GET_INVOICES}?${queryFilter}`,
				method: "GET"
			})
			return response.data.map((i: any) => InvoiceModel.fromRawJson(i))
		} catch (error: any) {
			throw new BillingError({
				errorMessage: BillingError.INVOICES_GET_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async exportInvoices(filter: InvoicesFilter): Promise<Blob> {
		try {
			const queryFilter = this.buildQueryFilter(filter)
			const response = await this._http.requestNewApi({
				url: `${Endpoint.EXPORT}?${queryFilter}`,
				method: "GET",
				responseType: "blob"
			})
			return response.data
		} catch (error: any) {
			throw new BillingError({
				errorMessage: BillingError.INVOICES_GET_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateInvoicesStatus(invoiceIds: number[], status: InvoiceStatus): Promise<void> {
		try {
			const response = await this._http.requestNewApi({
				url: Endpoint.UPDATE_STATUS,
				method: "PUT",
				data: { invoiceIds, status }
			})
			return response.data
		} catch (error: any) {
			throw new BillingError({
				errorMessage: BillingError.INVOICES_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}

	public async updateTransactionCreatedDate(
		transactionIds: number[],
		createdDate: Date,
		invoiceId: number
	): Promise<void> {
		try {
			const response = await this._http.requestNewApi({
				url: Endpoint.UPDATE_TRANSACTION_DATE,
				method: "POST",
				data: { transactionIds, createdDate, invoiceId }
			})
			return response.data
		} catch (error: any) {
			throw new BillingError({
				errorMessage: BillingError.INVOICES_UPDATE_ERROR,
				errorCode: error?.error_msg,
				httpStatus: error?.httpStatus
			})
		}
	}
}
