import { Card, Checkbox, Col, Row, Tooltip } from "antd"
import { observer } from "mobx-react"
import { FormField } from "../../../components/Form/FormField"
import { useTranslation } from "react-i18next"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { RequestServiceStatus } from "../../../../domain/enum/requestServiceStatus.enum"
import { TransactionFormData } from "../TransactionFormView"
import { PaymentGateway } from "../../../../domain/enum/paymentGateway.enum"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { TransactionsViewModel } from "../TransactionsViewModel"
import { CurrencyCode } from "../../../../domain/enum/currencyCode.enum"
import dayjs from "dayjs"
import { AppointmentStatus } from "../../../../domain/enum/appointmentStatus.enum"
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg"

interface TransactionInformationFormSectionProps {
	data: Partial<TransactionFormData>
	onChange?: (value: Partial<TransactionFormData>) => void
	editMode?: boolean
}

const TransactionInformationFormSection = ({ data, editMode, onChange }: TransactionInformationFormSectionProps) => {
	const { t } = useTranslation("transactions")
	const viewModel = useContainerInjection<TransactionsViewModel>("TransactionsViewModel")
	return (
		<>
			<Card
				bordered={false}
				className="criclebox tablespace mb-24"
				title={
					<div>
						<h6 className="font-semibold m-0">{t("form.transaction")}</h6>
					</div>
				}
				bodyStyle={{ padding: "24px" }}
			>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"id"}
							name={"id"}
							inputType={InputType.NUMBER}
							label={t("form.id")}
							placeHolder={t("form.id")}
							disabled
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"observations"}
							name={"observations"}
							inputType={InputType.TEXT}
							label={t("form.observations")}
							placeHolder={t("form.observations")}
							onChange={observations => onChange && onChange({ observations })}
						/>
					</Col>
				</Row>
			</Card>
			<Card
				bordered={false}
				className="criclebox tablespace mb-24"
				title={
					<div>
						<h6 className="font-semibold m-0">{t("form.selectService")}</h6>
					</div>
				}
				bodyStyle={{ padding: "24px" }}
			>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.DatePicker
							key={"requestDate"}
							name={"requestDate"}
							mandatory
							label={t("form.requestDate")}
							disabled={viewModel.isLoading}
							showTime
							placeHolder={t("form.requestDate")}
							onChange={requestDate =>
								onChange && onChange({ requestDate: requestDate ?? dayjs(new Date()) })
							}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"userEmail"}
							name={"userEmail"}
							inputType={InputType.EMAIL}
							mandatory
							label={t("form.userEmail")}
							placeHolder={t("form.userEmail")}
							disabled={viewModel.isLoading}
							onChange={userEmail => onChange && onChange({ userEmail })}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.SelectSearch
							key={"site"}
							label={t("form.site")}
							name={"site"}
							mandatory
							disabled={viewModel.isLoading}
							loading={viewModel.isLoading}
							value={data.micrositeSlug ?? data.marketplace}
							placeholder={t("form.sitePlaceholder").toString()}
							onChange={site => {
								const values =
									site.value === "ES" || site.value === "MX"
										? {
												site: site.value,
												marketplace: site.value,
												micrositeSlug: undefined,
												micrositeId: undefined
										  }
										: {
												site: site.value,
												micrositeId: site.key,
												micrositeSlug: site.value,
												marketplace: undefined
										  }
								onChange &&
									onChange({
										...values,
										voucherRuleId: undefined
									})
								viewModel.fetchVoucherRules({
									micrositeId: values.micrositeId,
									country: values.marketplace,
									serviceId: data.serviceId
								})
							}}
							options={[
								{
									id: "ES",
									label: `Marketplace ES`,
									value: "ES"
								},
								{
									id: "MX",
									label: `Marketplace MX`,
									value: "MX"
								}
							].concat(
								viewModel.microsites?.map(microsite => ({
									id: microsite.id!,
									key: microsite.id,
									label: `${microsite.id} - ${microsite.slug}`,
									value: microsite.slug
								})) ?? []
							)}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.SelectSearch
							key={"serviceId"}
							label={t("form.serviceName")}
							name={"serviceId"}
							mandatory
							disabled={viewModel.isLoading}
							loading={viewModel.isLoading}
							value={data.serviceId}
							placeholder={t("form.serviceName")}
							onChange={async serviceId => {
								onChange &&
									onChange({
										serviceId: serviceId.value,
										serviceOfficeId: undefined,
										sessionId: undefined,
										voucherRuleId: undefined,
										paymentBaseAmount: undefined,
										paymentFinalAmount: undefined
									})
								await viewModel.fetchServiceDetail(serviceId.value, {
									micrositeId: data.micrositeId,
									country: data.marketplace
								})
								onChange &&
									onChange({
										serviceId: serviceId.value,
										serviceName: viewModel.selectedService?.title,
										serviceOfficeId: undefined,
										sessionId: undefined,
										voucherRuleId: undefined,
										paymentBaseAmount: undefined,
										paymentFinalAmount: undefined,
										eventTimezone:
											viewModel.selectedService?.offices?.find(
												o => o.id === data?.serviceOfficeId
											)?.serviceCalendar?.timeZone ??
											(viewModel.selectedService?.countryId === "MX"
												? "America/Monterrey"
												: "Europe/Madrid")
									})
							}}
							options={
								viewModel.services?.map(service => ({
									id: service.id!,
									label: `${service.id} - ${service.title}`,
									value: service.id
								})) ?? [
									{
										id: data.serviceId,
										label: `${data.serviceId} - ${data.serviceName}`,
										value: data.serviceId
									}
								]
							}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Select
							key={"serviceOfficeId"}
							name={"serviceOfficeId"}
							label={t("form.serviceOffice")}
							placeholder={t("form.serviceOffice")}
							mandatory
							value={data.serviceOfficeId}
							options={viewModel.selectedService?.offices?.map(o => ({
								label: o.sellerOffice?.name,
								value: o.id
							}))}
							disabled={viewModel.isLoadingDetail}
							loading={viewModel.isLoadingDetail}
							onChange={serviceOfficeId =>
								onChange && onChange({ serviceOfficeId, sessionId: undefined })
							}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.SelectSearch
							key={"sessionId"}
							label={t("form.sessionName")}
							name={"sessionId"}
							mandatory
							disabled={viewModel.isLoadingDetail}
							loading={viewModel.isLoadingDetail}
							value={data.sessionId}
							placeholder={t("form.sessionName")}
							onChange={sessionId => {
								const calculatedPrice =
									viewModel.selectedService?.serviceSessions
										?.find(s => s.id === sessionId.value)
										?.multicurrency?.find(c => c.isoCode === data.currency)?.price ??
									data.paymentBaseAmount ??
									0
								onChange &&
									onChange({
										sessionId: sessionId.value,
										sessionName: sessionId.label,
										paymentBaseAmount: calculatedPrice * (data.units ?? 1),
										paymentFinalAmount: calculatedPrice * (data.units ?? 1)
									})
							}}
							options={
								viewModel.selectedService?.serviceSessions
									?.filter(s => s.serviceOfficeId === data.serviceOfficeId)
									?.map(session => ({
										id: session.id!,
										label: session.title,
										value: session.id
									})) ?? [{ id: data.sessionId, label: data.sessionName, value: data.sessionId }]
							}
						/>
					</Col>
				</Row>
			</Card>
			<Card
				bordered={false}
				className="criclebox tablespace mb-24"
				title={
					<div>
						<h6 className="font-semibold m-0">{t("form.paymentInformation")}</h6>
					</div>
				}
				bodyStyle={{ padding: "24px" }}
			>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Select
							key={"currency"}
							name={"currency"}
							label={t("form.currency")}
							placeholder={t("form.currency")}
							mandatory
							value={data.currency}
							options={[
								{ label: CurrencyCode.EUR, value: CurrencyCode.EUR },
								{ label: CurrencyCode.MXN, value: CurrencyCode.MXN }
							].filter(
								option =>
									!data.sessionId ||
									!viewModel.selectedService?.serviceSessions ||
									viewModel.selectedService?.serviceSessions
										.find(s => s.id === data.sessionId)
										?.multicurrency?.some(c => c.isoCode === option.value)
							)}
							onChange={currency => {
								const calculatedPrice =
									viewModel.selectedService?.serviceSessions
										?.find(s => s.id === data.sessionId)
										?.multicurrency?.find(c => c.isoCode === currency)?.price ??
									data.paymentBaseAmount ??
									0
								onChange &&
									onChange({
										currency,
										paymentBaseAmount: calculatedPrice * (data.units ?? 1),
										paymentFinalAmount: calculatedPrice * (data.units ?? 1)
									})
							}}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"units"}
							name={"units"}
							defaultValue="1"
							inputType={InputType.NUMBER}
							mandatory
							label={t("form.units")}
							placeHolder={t("form.units")}
							onChange={value => {
								const units = value ? Number(value) : 0
								const calculatedPrice =
									viewModel.selectedService?.serviceSessions
										?.find(s => s.id === data.sessionId)
										?.multicurrency?.find(c => c.isoCode === data.currency)?.price ??
									data.paymentBaseAmount ??
									0
								onChange &&
									onChange({
										units,
										paymentBaseAmount: calculatedPrice * units,
										paymentFinalAmount: calculatedPrice * units
									})
							}}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"paymentBaseAmount"}
							name={"paymentBaseAmount"}
							inputType={InputType.NUMBER}
							mandatory
							label={t("form.paymentBaseAmount")}
							placeHolder={t("form.paymentBaseAmount")}
							disabled={true}
							onChange={paymentBaseAmount =>
								onChange &&
								onChange({ paymentBaseAmount: paymentBaseAmount ? Number(paymentBaseAmount) : 0 })
							}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.SelectSearch
							key={"voucherRuleId"}
							label={t("form.voucher")}
							name={"voucherRuleId"}
							disabled={viewModel.isLoadingDetail}
							loading={viewModel.isLoadingDetail}
							value={data.voucherRuleId}
							placeholder={t("form.voucher")}
							onChange={voucherRuleId => {
								const voucherRule = viewModel.voucherRules?.find(r => r.id === voucherRuleId.value)
								const discount =
									voucherRule?.discountTypeName === "percentage"
										? (data.paymentBaseAmount ?? 0) * (voucherRule.discount / 100)
										: voucherRule?.discount ?? 0
								let paymentFinalAmount = data.paymentBaseAmount
									? Number((data.paymentBaseAmount - discount).toFixed(2))
									: 0
								if (data.points) paymentFinalAmount -= data.points
								onChange &&
									onChange({
										voucherRuleId: voucherRuleId.value,
										paymentFinalAmount: paymentFinalAmount > 0 ? paymentFinalAmount : 0
									})
							}}
							options={
								viewModel.voucherRules?.map(rule => ({
									id: rule.id!,
									label: rule.voucherCode,
									value: rule.id
								})) ??
								(data.voucherRuleId
									? [{ id: data.voucherRuleId, label: data.voucherCode, value: data.voucherRuleId }]
									: [])
							}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"points"}
							name={"points"}
							inputType={InputType.NUMBER}
							label={t("form.points")}
							placeHolder={t("form.points")}
							onChange={points => {
								let paymentFinalAmount = data.paymentBaseAmount ?? 0
								if (data.voucherRuleId) {
									const voucherRule = viewModel.voucherRules?.find(r => r.id === data.voucherRuleId)
									const discount =
										voucherRule?.discountTypeName === "percentage"
											? (data.paymentBaseAmount ?? 0) * (voucherRule.discount / 100)
											: voucherRule?.discount ?? 0
									paymentFinalAmount = paymentFinalAmount - discount
								}
								paymentFinalAmount -= points ? Number(points) : 0
								onChange &&
									onChange({
										points: points ? Number(points) : 0,
										paymentFinalAmount: paymentFinalAmount > 0 ? paymentFinalAmount : 0
									})
							}}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Input
							key={"paymentFinalAmount"}
							name={"paymentFinalAmount"}
							inputType={InputType.NUMBER}
							mandatory
							label={t("form.paymentFinalAmount")}
							placeHolder={t("form.paymentFinalAmount")}
							onChange={paymentFinalAmount =>
								onChange &&
								onChange({ paymentFinalAmount: paymentFinalAmount ? Number(paymentFinalAmount) : 0 })
							}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Select
							key={"paymentGateway"}
							name={"paymentGateway"}
							label={t("form.paymentGateway")}
							placeholder={data.status !== RequestServiceStatus.FREE ? t("form.paymentGateway") : "N/A"}
							options={
								data.status !== RequestServiceStatus.FREE
									? Object.values(PaymentGateway).map(option => ({
											label: option,
											value: option
									  }))
									: []
							}
							disabled={data.status === RequestServiceStatus.FREE}
							allowClear
							onChange={paymentGateway => onChange && onChange({ paymentGateway })}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.Select
							key={"status"}
							name={"status"}
							mandatory
							label={t("form.status")}
							placeholder={t("form.status")}
							options={Object.values(RequestServiceStatus).map(option => ({
								label: option,
								value: option
							}))}
							onChange={status => onChange && onChange({ status })}
						/>
					</Col>
				</Row>
			</Card>
			<Card
				bordered={false}
				className="criclebox tablespace mb-24"
				title={
					<div>
						<h6 className="font-semibold m-0">{t("form.appointmentInformation")}</h6>
					</div>
				}
				bodyStyle={{ padding: "24px" }}
			>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.DatePicker
							timezone={data.eventTimezone}
							className="mb-0"
							key={"eventDateTime"}
							name={"eventDateTime"}
							label={t("form.reservationDate")}
							showTime
							placeHolder={t("form.reservationDate")}
							onChange={eventDateTime => onChange && onChange({ eventDateTime })}
							disabled={!data.serviceId || viewModel.isLoadingDetail}
						/>
						<p className="text-xs text-gray-400 mt-0 ml-1">{data.eventTimezone}</p>
					</Col>
					<Col span={24} xl={12} md={12} xs={24}>
						<FormField.DatePicker
							key={"cancelledAt"}
							name={"cancelledAt"}
							mandatory={data.appointmentStatus === AppointmentStatus.CANCELLED_REFUND}
							label={
								<div className="flex items-center">
									{t("form.cancelledAt")}
									<Tooltip className="hover:cursor-pointer ml-2" title={t("form.cancelledAtHelp")}>
										<InfoIcon className="info-icon" />
									</Tooltip>
								</div>
							}
							showTime
							placeHolder={t("form.cancelledAt")}
							onChange={cancelledAt => onChange && onChange({ cancelledAt })}
						/>
					</Col>
				</Row>
				<Row gutter={[24, 0]}>
					<Col span={24} xl={12} md={12} xs={24}>
						<div className="flex items-center mb-3 pb-1">
							{t("form.appointmentStatus")}
							<Tooltip className="hover:cursor-pointer ml-2" title={t("form.appointmentStatusHelp")}>
								<InfoIcon className="info-icon" />
							</Tooltip>
						</div>
						<FormField.Select
							key={"appointmentStatus"}
							name={"appointmentStatus"}
							allowClear
							placeholder={t("form.appointmentStatus")}
							options={Object.values(AppointmentStatus).map(option => ({
								label: t(`form.dataTableRows.appointmentStatuses.${option}`),
								value: option
							}))}
							onChange={appointmentStatus => onChange && onChange({ appointmentStatus })}
						/>
					</Col>
					<Col span={24} xl={12} md={12} xs={24} className="flex items-center">
						<Checkbox
							className="ml-3 mt-4"
							key="sendEmails"
							defaultChecked={data.sendEmails}
							onChange={value => onChange && onChange({ sendEmails: value.target.checked })}
						>
							{t("form.sendEmails")}
						</Checkbox>
					</Col>
				</Row>
			</Card>
		</>
	)
}

export default observer(TransactionInformationFormSection)
