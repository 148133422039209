import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { Checkbox, Tooltip } from "antd"
import { useTranslation } from "react-i18next"
import { BillingViewModel } from "../BillingViewModel"
import { Link } from "react-router-dom"
import { Invoice } from "../../../../domain/entities/Invoice"
import dayjs from "dayjs"
import { EyeOutlined } from "@ant-design/icons"
import { toJS } from "mobx"
import { AuthStore } from "../../../stores/AuthStore"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"

interface InvoiceTableProps {
	data: Invoice[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const InvoiceTable = ({ data, loading, isFetching, onChangePage }: InvoiceTableProps) => {
	const viewModel = useContainerInjection<BillingViewModel>("BillingViewModel")
	const handlePageChange = (page: number, pageSize: number, lastPageFetched: number) => {
		viewModel.setTableConfig({ pageSize })
		onChangePage && onChangePage(page, pageSize, lastPageFetched)
	}
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	const { t } = useTranslation("billing")
	return (
		<DataTable
			loading={isFetching}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			isFetching={isFetching}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={handlePageChange}
			columns={TABLE_COLUMNS(value => viewModel.setSelectedInvoiceIds(value ? data.map(i => i.id) : []))}
			dataSource={data.map((invoice, idx) => {
				return {
					key: invoice.id,
					id: invoice.id,
					invoiceNumber: invoice.invoiceNumber,
					emissionDate: invoice.emissionDate ? dayjs(invoice.emissionDate).format("DD/MM/YYYY") : "-",
					status: t(invoice.status),
					period: invoice.period,
					sellerName: invoice.seller?.fiscalName ?? invoice.seller?.sellerName ?? "-",
					paymentDate: invoice.paymentDate ? dayjs(invoice.paymentDate).format("DD/MM/YYYY") : "-",
					paymentTerm: invoice.seller?.paymentDays ?? "-",
					total: `$${invoice.total}`,
					checkbox: isAdmin && (
						<Checkbox
							key={invoice.id}
							checked={viewModel.selectedInvoiceIds.includes(invoice.id)}
							onChange={value => {
								viewModel.setSelectedInvoiceIds(
									value.target.checked
										? viewModel.selectedInvoiceIds.concat([invoice.id])
										: viewModel.selectedInvoiceIds.filter(id => id !== invoice.id)
								)
							}}
						></Checkbox>
					),
					viewButton: (
						<div className="ant-employed">
							<Link
								to={RoutePaths.BILLING_TRANSACTIONS}
								onClick={() => viewModel.setSelectedInvoice(invoice)}
							>
								<Tooltip title={"View"}>
									<EyeOutlined />
								</Tooltip>
							</Link>
						</div>
					)
				}
			})}
		/>
	)
}

export default observer(InvoiceTable)
