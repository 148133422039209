import { observer } from "mobx-react"
import { useLocation, useNavigate } from "react-router-dom"
import { BillingViewModel } from "../BillingViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import InvoiceTransactionsTable from "./InvoiceTransactionsTable"
import { Invoice } from "../../../../domain/entities/Invoice"
import { Button, Card, Col, Row, Typography } from "antd"
import { useTranslation } from "react-i18next"
import { FormField } from "../../../components/Form/FormField"
import { useState } from "react"

const { Title } = Typography

const InvoiceFormView = () => {
	const { t } = useTranslation("billing")
	const navigate = useNavigate()
	const [createdDate, setCreatedDate] = useState<Date>(new Date())
	const viewModel = useContainerInjection<BillingViewModel>("BillingViewModel")
	const invoice = viewModel.selectedInvoice
	if (!invoice) {
		navigate("/billing")
		window.location.reload()
	}
	return (
		invoice && (
			<>
				<div className="tabled">
					<Row gutter={[24, 0]}>
						<Col xs={24} xl={24}>
							<Card
								bordered={false}
								className="criclebox tablespace mb-24"
								title={
									<div className="flex items-center justify-between">
										<Title className="mt-3" level={4}>
											{t("title")}
										</Title>
										<div className="flex items-center gap-16">
											<div className="flex">
												<FormField.DatePicker
													picker="month"
													key={"requestDate"}
													name={"requestDate"}
													disabled={viewModel.selectedInvoice?.status !== "pending_emission"}
													mandatory
													label={t("form.requestDate")}
													showTime
													placeHolder={t("form.requestDate")}
													onChange={requestDate =>
														setCreatedDate(requestDate ? requestDate.toDate() : new Date())
													}
												/>
												<Button
													className="ml-2"
													type="primary"
													disabled={viewModel.selectedInvoice?.status !== "pending_emission"}
													onClick={async () => {
														await viewModel.updateTransactionCreatedDate(
															invoice.id,
															createdDate
														)
													}}
												>
													{t("updateSelected")}
												</Button>
											</div>
										</div>
									</div>
								}
							>
								<InvoiceTransactionsTable
									loading={viewModel.isLoading}
									isFetching={viewModel.isFetching}
									onChangePage={(page, pageSize, lastPageFetched) => {
										if (
											page >= lastPageFetched - 1 &&
											viewModel.hasNextPage &&
											!viewModel.isFetching
										) {
											if (viewModel.pagination > 1 && viewModel.limit !== 200) {
												viewModel.setLimit(200)
												viewModel.setPagination(0)
												return
											}
											viewModel.setPagination(viewModel.pagination + 1)
										}
									}}
								/>
							</Card>
						</Col>
					</Row>
				</div>
			</>
		)
	)
}

export default observer(InvoiceFormView)
