import { useTranslation } from "react-i18next"
import { useContainerInjection } from "../../hooks/useContainerInjection"
import { TransactionsViewModel } from "./TransactionsViewModel"
import { observer } from "mobx-react"
import MainForm, { MainFormRef } from "../../components/Form/MainForm"
import { createRef, useEffect, useState } from "react"
import { Transaction } from "../../../domain/entities/Transaction"
import { useLocation, useNavigate } from "react-router-dom"
import TransactionInformationFormSection from "./components/TransactionInformationFormSection"
import dayjs, { Dayjs } from "dayjs"
import { toJS } from "mobx"

export interface TransactionFormData extends Omit<Transaction, "requestDate" | "eventDateTime" | "cancelledAt"> {
	site?: string
	requestDate: Dayjs
	eventDateTime?: Dayjs | null
	cancelledAt?: Dayjs | null
	sendEmails: boolean
}

const TransactionsFormView = () => {
	const viewModel = useContainerInjection<TransactionsViewModel>("TransactionsViewModel")
	const locationState: { transaction: Transaction } = useLocation().state ?? {}
	const [formData, setFormData] = useState<TransactionFormData>({
		...(locationState.transaction ?? {}),
		site: locationState.transaction?.micrositeSlug ?? locationState?.transaction?.marketplace,
		requestDate: dayjs(locationState.transaction?.requestDate ?? new Date()),
		eventDateTime: locationState.transaction?.eventDateTime
			? dayjs(locationState.transaction.eventDateTime).tz(locationState.transaction.eventTimezone)
			: undefined,
		cancelledAt: locationState.transaction?.cancelledAt ? dayjs(locationState.transaction.cancelledAt) : undefined,
		sendEmails: false
	})
	const navigate = useNavigate()
	const formRef = createRef<MainFormRef>()
	const onSubmitForm = async () => {
		let transactionId = formData.id
		if (transactionId) {
			await viewModel.updateTransaction(
				{
					...formData,
					paymentBaseAmount: formData.paymentBaseAmount,
					requestDate: formData.requestDate.toISOString(),
					eventDateTime: formData.eventDateTime?.toISOString(),
					cancelledAt: formData.cancelledAt?.toISOString()
				},
				formData.sendEmails
			)
		} else {
			transactionId = await viewModel.createTransaction({
				serviceId: formData.serviceId,
				serviceSessionId: formData.sessionId,
				currency: formData.currency,
				marketplace: formData.marketplace,
				micrositeId: formData.micrositeId,
				email: formData.userEmail,
				voucherRuleId: formData.voucherRuleId,
				points: formData.points,
				paymentId: formData.paymentId,
				paymentGateway: formData.paymentGateway,
				paymentBaseAmount: formData.paymentBaseAmount,
				paymentFinalAmount: formData.paymentFinalAmount,
				status: formData.status,
				observations: formData.observations,
				requestDate: formData.requestDate.toISOString(),
				startDateTime: formData.eventDateTime?.toISOString(),
				cancelledAt: formData.cancelledAt?.toISOString(),
				sendEmails: formData.sendEmails,
				appointmentStatus: formData.appointmentStatus,
				serviceOfficeId: formData.serviceOfficeId,
				units: formData.units
			})
			setFormData({ ...formData, id: transactionId })
		}
		navigate(".", {
			replace: true,
			state: {
				...locationState,
				transaction: {
					...toJS(formData),
					requestDate: formData.requestDate.toISOString(),
					eventDateTime: formData.eventDateTime?.toISOString(),
					cancelledAt: formData.cancelledAt?.toISOString(),
					id: transactionId
				}
			}
		})
	}
	useEffect(() => {
		viewModel.fetchMicrositesAndServices()
	}, [])
	useEffect(() => {
		if (locationState.transaction?.serviceId) {
			viewModel.fetchServiceDetail(locationState.transaction.serviceId, {
				micrositeId: locationState.transaction?.micrositeId,
				country: locationState.transaction?.marketplace
			})
		}
	}, [locationState.transaction])
	return (
		<MainForm onSubmitForm={onSubmitForm} initialValues={{ ...formData }} ref={formRef}>
			<TransactionInformationFormSection
				data={formData}
				onChange={values => setFormData({ ...formData, ...values })}
				editMode={!!formData.id}
			/>
		</MainForm>
	)
}

export default observer(TransactionsFormView)
