import { DatePicker, Form, FormItemProps } from "antd"
import { useTranslation } from "react-i18next"
import { Mandatory } from "./FormField"
import { calcFormFieldValidationRule } from "../../utils/calFormFieldValidationRule"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
dayjs.extend(utc)
dayjs.extend(timezone)

export interface FormDatePickerProps extends FormItemProps {
	onChange?: (date: dayjs.Dayjs | null) => void
	value?: dayjs.Dayjs
	defaultValue?: dayjs.Dayjs
	mandatory?: Mandatory
	picker?: "time" | "date" | "week" | "month" | "quarter" | "year"
	validationMessage?: string
	disabled?: boolean
	showTime?: boolean
	placeHolder?: string
	timezone?: string
}

export const FormDatePicker = ({
	onChange,
	mandatory,
	validationMessage,
	disabled,
	picker,
	showTime,
	placeHolder,
	timezone,
	value,
	defaultValue,
	...props
}: FormDatePickerProps) => {
	const { t } = useTranslation("mainForm", { keyPrefix: "validations" })

	if (validationMessage && !mandatory)
		console.warn(
			"FormTextInput: 'mandatory' prop is not set to true, but 'validationMessage' is set. Validation message will not be shown."
		)
	return (
		<Form.Item {...props} rules={calcFormFieldValidationRule(mandatory, validationMessage ?? t("mandatoryField"))}>
			<DatePicker
				picker={picker}
				defaultValue={defaultValue}
				value={value}
				allowClear={false}
				className="w-full"
				onSelect={date =>
					onChange && onChange(timezone ? dayjs.tz(date?.format("YYYY-MM-DD HH:mm"), timezone) : date)
				}
				disabled={disabled}
				showTime={showTime}
				placeholder={placeHolder}
			/>
		</Form.Item>
	)
}
