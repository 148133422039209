import { observer } from "mobx-react"
import DataTable from "../../../components/DataTable/DataTable"
import TABLE_COLUMNS from "../const/TABLE_COLUMNS"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { TransactionsViewModel } from "../TransactionsViewModel"
import DataTableRowsSkeleton from "../../../styledComponents/LoadingSkeletons/DataTableRowsSkeleton"
import { Transaction } from "../../../../domain/entities/Transaction"
import { Tooltip } from "antd"
import { Translation } from "../../../stores/TranslationStore"
import { Microsite } from "../../../../domain/entities/Microsite"
import { currencyFormatter } from "../../../utils/currencyFormatter"
import { Link } from "react-router-dom"
import { RoutePaths } from "../../../navigation/RoutePaths.enum"
import { toJS } from "mobx"
import { EditFilled } from "@ant-design/icons"
import { AuthStore } from "../../../stores/AuthStore"
import { useTranslation } from "react-i18next"

interface TransactionTableProps {
	data: Transaction[]
	loading?: boolean
	isFetching?: boolean
	onChangePage?: (page: number, pageSize: number, lastPageFetched: number) => void
}

const TransactionsTable = ({ data, loading, isFetching, onChangePage }: TransactionTableProps) => {
	const viewModel = useContainerInjection<TransactionsViewModel>("TransactionsViewModel")
	const handlePageChange = (page: number, pageSize: number, lastPageFetched: number) => {
		viewModel.setTableConfig({ pageSize })
		onChangePage && onChangePage(page, pageSize, lastPageFetched)
	}
	const translation = useContainerInjection<Translation>("Translation")
	const { isAdmin } = useContainerInjection<AuthStore>("AuthStore")
	const { t } = useTranslation("transactions")

	return (
		<DataTable
			loading={loading}
			tableConfig={{
				sort: viewModel.tableConfig.sort,
				pageSize: viewModel.tableConfig.pageSize
			}}
			isFetching={isFetching}
			renderCustomLoadingComponent={() => <DataTableRowsSkeleton size="lg" />}
			onChange={data => {
				data.sorter &&
					viewModel.setTableConfig({ sort: { order: data.sorter.order, field: data.sorter.field } })
			}}
			onChangePage={handlePageChange}
			columns={TABLE_COLUMNS(isAdmin)}
			dataSource={data.map((tran, idx) => {
				const transactionDate = new Date(tran.requestDate.replace("T", " ").replace("Z", ""))
				return {
					key: tran.id,
					id: tran.id,
					invoiceId: tran.invoiceId,
					userEmail: tran.userEmail ?? "-",
					marketplace: tran.marketplace ?? "-",
					micrositeType:
						Microsite.businessTypes.find(type => type.id === tran.micrositeBusinessTypeId)?.label ?? "-",
					micrositeSlug: tran.micrositeSlug ?? "-",
					serviceName: (
						<div className="truncate" style={{ width: "10rem" }}>
							<Tooltip placement="topLeft" title={tran.serviceName}>
								{tran.serviceName}
							</Tooltip>
						</div>
					),
					sessionName: (
						<div className="truncate" style={{ width: "10rem" }}>
							<Tooltip placement="topLeft" title={tran.sessionName}>
								{tran.sessionName}
							</Tooltip>
						</div>
					),
					appointmentStatus: (
						<div className={"text-center"}>
							{tran.appointmentStatus
								? t(`form.dataTableRows.appointmentStatuses.${tran.appointmentStatus}`)
								: "-"}
						</div>
					),
					date: new Date(
						transactionDate.getFullYear(),
						transactionDate.getMonth(),
						transactionDate.getDate()
					).toLocaleDateString(translation.currentLang, {
						weekday: "short",
						year: "numeric",
						month: "short",
						day: "numeric"
					}),
					finalPrice: `${currencyFormatter(tran.paymentFinalAmount, tran.currency)}`,
					units: tran.units,
					cancelledAt: tran.cancelledAt ? new Date(tran.cancelledAt).toLocaleString() : "-",
					editButton: isAdmin && (
						<div className="ant-employed">
							<Link to={RoutePaths.TRANSACTIONS_EDIT} state={{ transaction: toJS(tran) }}>
								<Tooltip title={"Edit"}>
									<EditFilled />
								</Tooltip>
							</Link>
						</div>
					)
				}
			})}
		/>
	)
}

export default observer(TransactionsTable)
