import { Card, Col, Row } from "antd"
import { observer } from "mobx-react"
import { useTranslation } from "react-i18next"
import { FormField } from "../../../components/Form/FormField"
import { InputType } from "../../../components/TextInput/const/InputType.enum"
import { MicrositesViewModel } from "../MicrositesViewModel"
import { useContainerInjection } from "../../../hooks/useContainerInjection"
import { MicrositeObjective } from "../../../../domain/entities/MicrositeObjective"
import Switch from "../../../components/Switch/Switch"

interface Props {
	data?: Partial<MicrositeObjective> | null
	onChange?: (value: Partial<{ objectiveAmount: number; rewardAmount: number } | null>) => void
}

const MicrositeObjectiveFormSection = ({ data, onChange }: Props) => {
	const { t } = useTranslation("microsites", { keyPrefix: "form.objectiveInfo" })
	const viewModel = useContainerInjection<MicrositesViewModel>("MicrositesViewModel")
	return (
		<Card
			bordered={false}
			className="criclebox tablespace"
			title={
				<div className="flex justify-between items-center">
					<h4 className="font-semibold m-0">{t("title")}</h4>
					<Switch
						title={t("active")}
						checked={!!data}
						onChange={(value: boolean) =>
							onChange && onChange(value ? { objectiveAmount: 0, rewardAmount: 0 } : null)
						}
					/>
				</div>
			}
			style={{ height: "95%" }}
			bodyStyle={{ padding: "24px", paddingBottom: "48px" }}
		>
			<Row>
				<Col md={12} xl={12} xs={24}>
					<FormField.Input
						label={t("objective")}
						inputType={InputType.NUMBER}
						mandatory
						onChange={(objectiveAmount: string) =>
							onChange &&
							onChange({
								objectiveAmount: objectiveAmount ? Number(objectiveAmount) : 0
							})
						}
						disabled={!data}
						value={data?.objectiveAmount}
						customFieldValidation={
							data && !data.objectiveAmount
								? {
										status: "error",
										message: t("minValidation")
								  }
								: { status: "", message: "" }
						}
					/>
				</Col>
				<Col md={12} xl={12} xs={24}>
					<FormField.Input
						label={t("reward")}
						inputType={InputType.NUMBER}
						mandatory
						onChange={(rewardAmount: string) =>
							onChange &&
							onChange({
								rewardAmount: rewardAmount ? Number(rewardAmount) : 0
							})
						}
						disabled={!data}
						value={data?.rewardAmount}
						customFieldValidation={
							data && !data.rewardAmount
								? {
										status: "error",
										message: t("minValidation")
								  }
								: { status: "", message: "" }
						}
					/>
				</Col>
			</Row>
		</Card>
	)
}

export default observer(MicrositeObjectiveFormSection)
