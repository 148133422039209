import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"
import { Checkbox } from "antd"

export default (onSelectAll?: (value: boolean) => void) => {
	const { t } = useTranslation("billing", { keyPrefix: "dataTableHeaders" })

	return columns(t, onSelectAll)
}

const columns = (t: TFunction, onSelectAll?: (value: boolean) => void) => [
	,
	{
		title: (
			<div className="flex items-center text-bold gap-2">
				{t("select")}
				<Checkbox key="select_all" onChange={value => onSelectAll && onSelectAll(value.target.checked)} />
			</div>
		),
		key: "checkbox",
		dataIndex: "checkbox",
		width: "8rem",
		align: "center"
	},
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		width: "4rem",
		align: "center"
	},
	{
		key: "invoiceNumber",
		title: t("invoiceNumber"),
		dataIndex: "invoiceNumber",
		align: "center",
		width: "10rem"
	},
	{
		key: "emissionDate",
		title: t("emissionDate"),
		dataIndex: "emissionDate",
		align: "center",
		width: "10rem"
	},
	{
		key: "status",
		title: t("status"),
		dataIndex: "status",
		align: "center",
		width: "10rem"
	},
	{
		key: "period",
		title: t("period"),
		dataIndex: "period",
		align: "center",
		width: "5rem"
	},
	{
		key: "sellerName",
		title: t("sellerName"),
		dataIndex: "sellerName",
		align: "center",
		width: "10rem"
	},
	{
		key: "paymentTerm",
		title: t("paymentTerm"),
		dataIndex: "paymentTerm",
		align: "center",
		width: "8rem"
	},
	{
		key: "paymentDate",
		title: t("paymentDate"),
		dataIndex: "paymentDate",
		align: "center",
		width: "10rem"
	},
	{
		key: "total",
		title: t("total"),
		dataIndex: "total",
		align: "center",
		width: "8rem"
	},
	{
		title: "",
		key: "viewButton",
		dataIndex: "viewButton",
		needsAdmin: true
	}
]
