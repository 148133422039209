import { useTranslation } from "react-i18next"
import { TFunction } from "i18next"

export default (isAdmin?: boolean) => {
	const { t } = useTranslation("transactions", { keyPrefix: "form.dataTableHeaders" })

	return isAdmin !== undefined ? columns(t).filter(c => isAdmin || !c.needsAdmin) : columns(t)
}

const columns = (t: TFunction) => [
	{
		key: "id",
		title: "ID",
		dataIndex: "id",
		sorteable: true
	},
	{
		key: "invoiceId",
		title: t("invoiceId"),
		dataIndex: "invoiceId",
		sorteable: true
	},
	{
		key: "userEmail",
		title: t("userEmail"),
		dataIndex: "userEmail",
		align: "center"
	},
	{
		key: "marketplace",
		title: t("marketplace"),
		dataIndex: "marketplace",
		width: "8rem",
		align: "center"
	},
	{
		key: "micrositeType",
		title: t("micrositeType"),
		dataIndex: "micrositeType",
		width: "10rem",
		align: "center"
	},
	{
		key: "micrositeSlug",
		title: t("micrositeSlug"),
		dataIndex: "micrositeSlug",
		align: "center"
	},
	{
		key: "serviceName",
		title: t("serviceName"),
		dataIndex: "serviceName"
	},
	{
		key: "sessionName",
		title: t("sessionName"),
		dataIndex: "sessionName"
	},
	{
		key: "appointmentStatus",
		title: t("appointmentStatus"),
		dataIndex: "appointmentStatus",
		align: "center",
		width: "10rem"
	},
	{
		key: "date",
		title: t("date"),
		dataIndex: "date"
	},
	{
		key: "finalPrice",
		title: t("finalPrice"),
		dataIndex: "finalPrice",
		width: "8rem",
		align: "center"
	},
	{
		key: "units",
		title: t("units"),
		dataIndex: "units",
		width: "6rem",
		align: "center"
	},
	{
		key: "cancelledAt",
		title: t("cancellationDate"),
		dataIndex: "cancelledAt",
		width: "10rem"
	},
	{
		title: "",
		key: "editButton",
		dataIndex: "editButton",
		needsAdmin: true
	}
]
